<template>
  <div
    :class="[
      'package-detail-wrapper',
      {
        'package-animation-enter': pageEnter === 'enter',
        'package-animation-leave': pageEnter === 'leave',
      },
    ]"
  >
    <div class="package-detail-wrapper-inner" :class="{ leave: isLeave }" v-show="!!$route.params.package">
      <client-only>
        <template v-if="!sim">
          <v-lottie position="fixed" />
        </template>
      </client-only>

      <div class="package-detail-container" v-if="!loader" @click.prevent="clickOutSide($event)">
        <div data-testid="package-detail" class="package-detail" v-if="sim">
          <sim-detail
            :sim="sim"
            :global-sim-networks="globalNetworks"
            :country="selectedCountry"
            @clickClose="closePackageDetail()"
            @clickCallToAction="clickPackageDetail"
          />
        </div>
      </div>
      <div class="package-detail-backdrop" @click.prevent="closePackageDetail()"></div>
    </div>
  </div>
</template>

<script>
  import pkgMxn from '~/mixins/package.js'

  export default {
    name: 'pages-index-list-package',
    components: {
      SimDetail: () => import('~/components/sim/detail.vue'),
    },

    mixins: [pkgMxn(false, undefined)],

    props: {
      listType: {
        type: String,
        required: true,
      },
      selectedCountry: {
        type: Object,
        required: false,
        default: null,
      },
    },

    async asyncData({ params }) {
      const isTrueData = !!params.package
      let detailData = null

      return {
        isTrueData,
        detailData,
      }
    },

    data() {
      return {
        isLeave: false,
        sim: null,
        loader: false,
        globalNetworks: [],
        packageDetailOpenClass: true,
        pageEnter: false,
      }
    },

    async fetch() {
      if (this.$route.params.package) {
        if (process.client) {
          this.loader = true
        }
        await this.pageData('fetch')
      }
    },

    head() {
      if (this.sim && this.selectedCountry) {
        const metaInfos = this.buildMetaInfos()

        return {
          bodyAttrs: {
            class: this.packageDetailOpenClass ? 'package-detail-open' : '',
          },
          title: metaInfos ? metaInfos.title : '',
          meta: [
            {
              hid: 'description',
              name: 'description',
              content: metaInfos ? metaInfos.description : '',
            },
            {
              hid: 'keywords',
              name: 'keywords',
              content: metaInfos ? metaInfos.keywords : '',
            },
          ],
        }
      }
    },

    methods: {
      buildMetaInfos() {
        const metaInfos = {
          title: this.$t('seo.package.title'),
          description: this.$t('seo.package.description'),
          keywords: this.$t('seo.package.keywords'),
        }

        const matchKeys = ['data', 'country', 'validity']
        // START-NOSCAN
        const metaMatchRegex = new RegExp(/\{(.+?)\}/, 'g')
        // END-NOSCAN
        const metaInfosKeysArray = Object.keys(metaInfos)
        const metaInfosBodySubstitution = (body) => {
          if (body !== 'country') return this.sim[body]
          if (this.listType === 'regional-packages') return this.sim.operator.region.title
          if (this.listType === 'global') return this.sim.operator.region.title
          if (this.sim.operator.country) return this.sim.operator.country.title
          if (this.sim.operator.region) return this.sim.operator.region.title
          return ''
        }

        metaInfosKeysArray.forEach((v) => {
          let val = metaInfos[v].replace(metaMatchRegex, (_m, body, _index) => {
            if (matchKeys.includes(body)) {
              return metaInfosBodySubstitution(body)
            }
            return body
          })

          metaInfos[v] = val
        })

        return metaInfos
      },

      clickOutSide(e) {
        if (e.target.classList.contains('package-detail-container')) {
          this.closePackageDetail()
        }
      },
      closePackageDetail() {
        this.isLeave = true
        this.pageEnter = 'leave'

        setTimeout(() => {
          this.$router.push(
            this.localePath({
              name: 'index-list-package',
              params: {
                list: this.$route.params.list,
              },
            })
          )

          this.isLeave = false
          this.pageEnter = false
        }, 249)
      },

      async pageData(type, toPackageParam) {
        const packageParam = type === 'beforeRouteUpdate' ? toPackageParam : this.$route.params.package

        try {
          const simData = await this.$store.dispatch('fetch_package_details', packageParam)

          if (simData) {
            if (process.client || process.browser) {
              this.$utils.sendEvent('userSetData', {
                last_visited_package_name: simData.title,
                last_visited_package_id: simData.id,
              })
            }
          }

          if (this.listType === 'local-packages' && simData.operator.type === 'global') {
            this.$nextTick(() => {
              this.sim = null
              this.loader = false
              this.$root.context.redirect(
                301,
                this.localePath({
                  name: 'index-list',
                  params: {
                    list: this.$route.params.list,
                  },
                })
              )
            })

            return false
          }

          if (simData.type === 'topup') {
            let newPackageSlug = null

            if (simData.slug.includes('-topup')) {
              newPackageSlug = simData.slug.replace('-topup', '')
            }

            this.sim = null

            this.$root.context.redirect(
              301,
              this.localePath({
                name: newPackageSlug ? 'index-list-package' : 'index-list',
                params: {
                  list: this.$route.params.list,
                  ...(newPackageSlug && { package: newPackageSlug }),
                },
              })
            )

            return false
          }

          this.sim = simData
          this.loader = false
        } catch (error) {
          this.sim = null
          this.loader = false

          if (this.$route.params.list && this.$route.params.list.length > 0) {
            this.$nextTick(() => {
              this.$root.context.redirect(
                301,
                this.localePath({
                  name: 'index-list',
                  params: {
                    list: this.$route.params.list,
                  },
                })
              )
            })
          }
        }
      },

      clickPackageDetail(payload) {
        this.$router.push(this.localePath(payload.route))
      },
    },

    beforeRouteUpdate(to, from, next) {
      if (process.client) {
        if (!from.params.package && to.params.package) {
          this.packageDetailOpenClass = true
          this.loader = true

          this.pageData('beforeRouteUpdate', to.params.package)
        } else if (from.params.package && to.params.package && from.params.package.includes(to.params.package)) {
          this.pageData('beforeRouteUpdate', to.params.package)
        } else if (from.params.package && !to.params.package) {
          this.packageDetailOpenClass = false
        }
      }

      next()
    },

    beforeRouteLeave(to, from, next) {
      if (process.client) {
        if (!to.params.package) {
          this.packageDetailOpenClass = false
        }
      }
      next()
    },

    created() {
      if (process.client) {
        this.pageEnter = 'enter'
        if (this.$route.params.package) {
          this.packageDetailOpenClass = true
        }
      }
    },

    watch: {
      sim(val) {
        if (process.client && val) {
          this.$emit(
            'onPackageDetailMounted',
            this.sim?.operator?.plan_type !== 'data-voice-text' ? 'others' : 'data-voice-text'
          )
        }
      },
    },

    scrollToTop: false,
  }
</script>

<style lang="scss" scoped>
  .package-detail {
    box-shadow: 0 10px 30px 0 rgba(0, 0, 0, 0.15);
    width: 100%;
    max-width: 730px;
    cursor: default;

    @media only screen and (max-width: 768px) {
      border-radius: 0px;
      max-width: unset;
    }

    &-wrapper {
      &-inner {
        position: fixed;
        left: 0;
        top: 0;
        width: 100vw;
        height: 100%;
        z-index: 101;

        overflow: hidden;
        overflow-y: auto;
        padding: 40px 0;

        display: flex;
        justify-content: center;
        align-items: flex-start;

        @media only screen and (max-width: 768px) {
          padding: 0;
          overflow-y: unset;
        }

        .preloader {
          transform: scale(1.125);
          &-wrapper {
            background-color: transparent;
            z-index: 102;
          }
        }
      }
    }

    &-backdrop {
      z-index: 101;
      position: fixed;
      left: 0;
      top: 0;
      background-color: rgba(250, 250, 250, 0.75);
      width: 100%;
      height: 100%;
      cursor: pointer;
    }

    &-container {
      position: relative;
      z-index: 102;
      transform: translate3d(0, 0px, 0);
      width: 100%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      cursor: pointer;

      @media only screen and (max-width: 768px) {
        transform: translate3d(0, 0, 0);
        overflow-y: auto;
        height: 100%;
      }
    }

    ::v-deep .sim-item-row p.value {
      width: 100%;
      text-align: end;
    }
  }
</style>
